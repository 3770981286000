import './App.css';

const { REACT_APP_IFRAME_SOURCE } = process.env;

const iframeResizer = () => {
  const iframeEle = document.getElementById('enamel-iframe');

  window.iFrameResize({log: false, scrolling: 'omit'}, iframeEle);
};

const App = () => {
  return (
    <div className="App">
      <div className='App-container'>
        <header className="App-header">
          <img src='./images/header-image.png' alt="Header" className='header-image'/>
        </header>
        <main className='App-main'>
          <iframe
            id='enamel-iframe'
            className='App-iframe'
            title="app-iframe"
            allow="payment"
            allowpaymentrequest
            src="https://foreversmilemembershipplan.com"
            width="100%"
            onLoad={iframeResizer}
            style={{
              height: "100vh",
            }}
          />
        </main>
        <footer className="App-footer">
          <img src='./images/footer-image.png' alt="Footer" className='footer-image'/>
        </footer>
      </div>
    </div>
  );
}

export default App;
